import React, { Component } from "react"
import Img from "gatsby-image"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

import "../css/Clients.css"

const Logoimg = props => {
  let clientsImages = props.clientsrow.edges

  let title = props.title
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10 col-md-12 btn-compliance">
            <div className="btn-enabling-compliance">
              <button className="btn-clients">{title}</button>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
        <hr className="client-line" />
        <div className="row pt-lg-4 pb-lg-4 py-4">
          <div className="col-lg-12 col-md-12">
            <Carousel
              responsive={responsive}
              centerMode={true}
              autoPlay
              infinite={true}
              autoPlaySpeed={3000}
            >
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-344-1.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-385.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-386.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-387.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-388.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-389.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-390.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-391.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-392.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-393.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-394.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-395.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-396.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-397.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-398.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-399-1.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-400.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-401.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-402.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-403.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-404.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-405.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-406.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-407.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-408.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-409.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-410.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-411.png" />
              <img src="https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-412.png" />
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default Logoimg

// const Logoimg = props => {
//   let clientsImages = props.clientsrow.edges
//   console.log(clientsImages)
//   let title = props.title

//   const responsive = {
//     superLargeDesktop: {
//       // the naming can be any, depends on you.
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5,
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 4,
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2,
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1,
//     },
//   }

//   return (
//     <>
//       <div className="container-fluid ">
//         <div className="row">
//           <div className="col-lg-1"></div>
//           <div className="col-lg-10 col-md-12 btn-compliance">
//             <div className="btn-enabling-compliance">
//               <button className="btn-clients">{title}</button>
//             </div>
//           </div>
//           <div className="col-lg-1"></div>
//         </div>
//         <hr className="client-line" />
//         <div className="row pt-lg-4 pb-lg-4 py-4">
//           <Carousel
//             responsive={responsive}
//             arrows
//             autoPlay
//             autoPlaySpeed={2000}
//             containerClass="carousel-container"
//             className="coursel"
//             draggable
//             infinite
//             keyBoardControl
//             renderDotsOutside={true}
//             showDots={true}
//             sliderClass=""
//             slidesToSlide={2}
//           >
//             {clientsImages.map(({ node }) => (
//               <div>
//                 <img
//                   src={
//                     node.acf.client_logo_images.localFile.childImageSharp.fluid
//                       .originalImg
//                   }
//                   alt={node.acf.client_logo_images.alt_text}
//                 />
//               </div>
//             ))}
//           </Carousel>
//         </div>
//       </div>
//     </>
//   )
// }

// export default Logoimg
